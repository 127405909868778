// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colours
$primary: #154734;

// Brand Colours: Primaries
$trent-primary-green: #154734;
$trent-primary-blue: #5B7F95;

// Brand Colours: Highlights
$trent-secondary-gold: #C0B561;
$trent-secondary-teal: #115E67;

// Brand Colours Accents:
$trent-accent-orange: #B15533;
$trent-accent-green: #A9C47F;

