// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fontsource/nunito';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap Icons
@import '~bootstrap-icons/font/bootstrap-icons.css';

// Datatables BS5
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";

// Custom Styles
// @import "../css/custom.css";
.list-group-item strong {
    padding-right: 10px;
}

.timeline span {
    z-index: 3;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 11px;
    left: 2rem;
    height: 100%;
    border-right: 2px solid $trent-primary-green;
    z-index: 2;
}

.timeline:last-child:before {
    content: "";
    position: absolute;
    top: 0;
    left: 2rem;
    height: 100%;
    border-right: none;
}



.contact-note-type-icon i {
    background-color: $trent-primary-green;
    border-radius: 50%;
    padding: 10px;
    color: white;
}

.side-bar-nav {
    left:30px;

    a {
        color: $black;
    }
}

.header-image {
    z-index:-999; 
    min-height:300px; 
    
    background: linear-gradient(to right,
        rgba($trent-primary-green, 0.7), 
        rgba($trent-primary-green, 0.7)
      ),url('../images/headerImg.jpg');
    background-size:cover;
    // background-color: $trent-primary-green;
    clip-path: ellipse(117% 100% at 50% 0%);
}

.top-nav {
    background-color: white;
    color:black;
}

.top-nav i {
    color: $trent-primary-green;
}

.nav-btn {
    border:none;
}

.trent-item-list {
    a {
        &:hover {
            i.bi-chevron-right {
                transition: translateX .2s ease-in-out;
                transform:translateX(10px);
            }
            // color: white;
            // background-color:rgb(225, 225, 225);
            // box-shadow: $box-shadow;
            // font-weight: bold;
            

            
        }
        &:first-child {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }

        &:last-child {
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

        

        border:none;
        border-radius: 1rem;

        
    }

    
        

    
    
    


    

}

@include media-breakpoint-up(sm) {
    .content {
        margin-left:280px;
    }
    .side-bar-nav {
        visibility: visible !important;
        transform: none;
    }

    .top-nav {
        background:transparent;
        box-shadow: none !important;
        color:white;
    }

    .top-nav i {
        color: white;
    }

    

    .offcanvas-backdrop {
        display:none;
    }
}
